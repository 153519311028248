<!-- 计划装运日期表头 -->
<template>
  <p>
    <span v-if="required" class="require">*</span>
    <span>{{ $t('orderList.plannedShipmentDate') }}</span>
    <el-tooltip placement="top-start">
      <div slot="content" style="width: 200px">{{ $t(langkey) }}</div>
      <i class="el-icon-question"></i>
    </el-tooltip>
  </p>
</template>
<script>
  export default {
    name: 'PlannedShipmentDate',
    props: {
      tipType: {
        type: String, //订单模块
        default: 'order',
      },

      required: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {}
    },
    computed: {
      langkey() {
        if (this.tipType === 'order') {
          return 'orderList.plannedShipmentDateTip'
        }
        return ''
      },
    },
  }
</script>

<style scoped>
  .require {
    color: red;
  }
</style>
