<template>
  <div class="calculated-date-picker">
    <el-date-picker
      v-model="selectedDate"
      type="date"
      :placeholder="$t('placeholder.selDate')"
      v-bind="$attrs"
      value-format="yyyy-MM-dd"
      format="yyyy-MM-dd"
      :disabled="disabled"
      v-on="$listeners"
      @change="handleDateChange"
    />
    <el-tooltip
      placement="top"
      :disabled="calcType !== 'customerDeliveryDate'"
      content-class="tooltip-content"
    >
      <div slot="content" style="width: 200px">
        <div v-if="lang !== 'en'">
          <p>
            1、点击此按钮获取物流方预估的客户交期，
            <span style="color: #ffff80">
              与 Product Pricing
              页面可能存在差异；并且计算的日期为美国客户的交期，
            </span>
            其他国家的客户交期请与客户代表或物流部门确认。
          </p>
          <p style="margin-top: 15px">
            2、若得到的交期不能满足客户要求的 Firm
            交期，请尝试其他运输方式，或与产品运营或采购部门确认。
          </p>
        </div>
        <div v-else>
          <p>
            1、Click this button to get the estimated delivery date from the
            logistics provider,
            <span style="color: #ffff80">
              which may differ from the Product Pricing page. This date applies
              only to US mainland customers.
            </span>
            Please confirm with customer representatives or the logistics
            department for customers in other countries and regions.
          </p>
          <p style="margin-top: 15px">
            2、If the provided delivery date does not meet the customer's
            required firm delivery date, please try other shipping methods or
            confirm with the product owners or procurement department.
          </p>
        </div>
      </div>
      <el-button
        type="primary"
        circle
        size="mini"
        icon="el-icon-date"
        @click.stop.prevent="doFetchDate"
      />
    </el-tooltip>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { ShipmentMangeInteractor } from '@/core'
  import { debounce } from 'kits'
  export default {
    name: 'CalculatedDatePicker',
    props: {
      value: {
        // 绑定值
        type: String,
        default: '',
      },
      calcType: {
        // 计算类型 计划装运日期 交货日期
        type: String,
        default: 'customerDeliveryDate', //交货日期
      },
      // 产品信息
      productInfo: {
        // 产品信息
        type: Object,
        default: () => ({}),
      },
      // 地址列表
      addressList: {
        // 地址列表
        type: Array,
        default: () => [],
      },
      quantType: {
        // 分配类型 1平均分配 2自行分配
        type: Number,
        default: 1,
      },

      checkDeliveryDate: {
        // 切换运输方式后是否需要对客户交期进行校验
        type: Boolean,
        default: false,
      },

      disabled: {
        // 表单禁用
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },

    data() {
      return {
        selectedDate: this.value,
      }
    },
    watch: {
      value(newValue) {
        this.selectedDate = newValue
        this.checkDate()
      },
    },
    methods: {
      // 校验日期
      checkDate: debounce(function () {
        if (this.selectedDate) {
          const productExtend = this.productInfo.productExtend || {}
          const { planShippingDate, customerDeliveryDate } = productExtend
          if (this.calcType !== 'customerDeliveryDate') {
            // 选择的是计划装运日期
            if (customerDeliveryDate) {
              if (
                new Date(this.selectedDate) > new Date(customerDeliveryDate)
              ) {
                this.$message.warning(
                  this.lang === 'en'
                    ? `The plan shipping date cannot be later than the customer delivery date.`
                    : `计划装运日期不能晚于客户交货日期。`
                )
              }
            }
          } else {
            if (planShippingDate) {
              if (new Date(planShippingDate) > new Date(this.selectedDate)) {
                this.$message.warning(
                  this.lang === 'en'
                    ? `The plan shipping date cannot be later than the customer delivery date.`
                    : `计划装运日期不能晚于客户交货日期。`
                )
              }
            }
          }
        }
      }, 500),
      // 校验日期是否可以计算
      checkDateParamPass() {
        const {
          amount,
          orderProductUniqueId,
          printingWayEn,
          productId,
          sku,

          productExtend: {
            printingPositionValueEn,
            valuationMethodValueEn,
            valuationMethodValue,
            planShippingDate,
            shippingMethodType: transportType,
          },
        } = this.productInfo
        const isSampleOrBlank = ['Sample', 'Blank'].includes(printingWayEn)
        if (this.calcType !== 'customerDeliveryDate') {
          // 计算计划装运日期

          // 解构出来的字段都不存在时返回false
          if (
            (!isSampleOrBlank &&
              ([
                amount,
                orderProductUniqueId,
                printingPositionValueEn,
                printingWayEn,
                productId,
                sku,
              ].some((filed) => !filed) ||
                (!valuationMethodValueEn && !valuationMethodValue))) ||
            (isSampleOrBlank && !amount)
          ) {
            const langKey = isSampleOrBlank
              ? 'orderList.blankPlannedShipmentDateRequired'
              : 'orderList.plannedShipmentDateRequired'
            this.$message.warning(this.$t(langKey))
            return false
          }
          return true
        } else {
          // 交货日期
          if (
            (!isSampleOrBlank &&
              [
                amount,
                orderProductUniqueId,
                planShippingDate,
                printingWayEn,
                productId,
                sku,
                transportType,
              ].some((field) => !field)) ||
            (isSampleOrBlank && (!amount || !planShippingDate))
          ) {
            const langKey = isSampleOrBlank
              ? 'orderList.blankCustomerDeliveryTimeRequired'
              : 'orderList.customerDeliveryTimeRequired'
            this.$message.warning(this.$t(langKey))
            return false
          }

          return true
        }
      },

      // 计算日期
      doFetchDate() {
        if (this.calcType !== 'customerDeliveryDate') {
          this.fetchShipDate()
        } else {
          // 校验通过后返回
          if (this.checkDateParamPass()) {
            this.$emit('calc-delivery', this.fetchCustomDeliveryDate.bind(this))
          }
        }
      },
      // 计算客户交期
      async fetchCustomDeliveryDate() {
        ShipmentMangeInteractor.calculateDeliveryDateApi(
          this.productInfo,
          this.addressList,
          this.quantType
        ).then((res) => {
          if (res?.code === '000000') {
            const customerDeliveryDate = res?.data?.customerDeliveryDate
            const productExtend = this.productInfo.productExtend
            if (customerDeliveryDate) {
              if (
                this.checkDeliveryDate &&
                productExtend.customerDeliveryDate &&
                productExtend.firmFlag == 1 &&
                new Date(productExtend.customerDeliveryDate).getTime() <
                  new Date(customerDeliveryDate).getTime()
              ) {
                this.$message.warning(
                  `该运输方式预计交期：${productExtend.customerDeliveryDate}，可能无法满足客户的 Firm 交期，请谨慎选择`
                )
              } else {
                this.$emit('input', customerDeliveryDate)
              }
            }
          }
        })
      },

      // 计算计划装运日期
      async fetchShipDate() {
        if (!this.checkDateParamPass()) {
          return Promise.resolve('')
        }

        ShipmentMangeInteractor.calculateShipDateApi(this.productInfo).then(
          (res) => {
            if (res?.code === '000000') {
              if (res?.data?.planShippingDate) {
                this.$emit('input', res?.data?.planShippingDate)
              }
            }
          }
        )
      },

      // 日期变化
      handleDateChange(value) {
        this.$emit('input', value)
      },
    },
  }
</script>

<style scoped>
  .calculated-date-picker {
    display: flex;
    align-items: center;
  }
  .el-button {
    margin-left: 4px;
  }
</style>
